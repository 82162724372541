@import 'src/global/mixins.scss';

.buttonContainer {
  @include flex-center;

  .scanButton {
    @include rounded;
    padding: var(--global-space-single);
    margin-bottom: var(--global-space-single);
  }
}
