@import 'src/global/mixins.scss';

.content {
  display: grid;
  grid-template-columns: 200px 280px;
  row-gap: var(--global-space-triple);
  font-size: 16px;

  .title {
    padding: calc(var(--global-space-single) - 1px) 0; // to compensate for the button's font-size
  }

  textarea {
    width: 100%;
    height: 100px;
  }
}
