@import 'src/global/mixins.scss';

.scanButtonsContainer {
  @include flex-start;
  flex-direction: column;

  .responseMessage {
    font-size: 12px;

    &.success {
      color: green;
    }

    &.failure {
      color: red;
    }
  }
}
