@import 'src/global/mixins.scss';

.globalFilter {
  @include flex-horizontal;
  justify-content: space-between;
  width: min(350px, 100vw);
  position: relative;
  margin-bottom: var(--global-space-single);

  label {
    margin-right: var(--global-space-single);
  }

  &:last-of-type {
    margin-bottom: var(--global-space-triple);
  }

  .customTimePeriodFilters {
    @include flex-horizontal;
  }
}
