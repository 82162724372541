@import 'src/global/mixins';

.select-all-container {
  @include flex-horizontal;

  .select-all {
    @include rounded;
    padding: var(--global-space-single);
    margin-bottom: var(--global-space-single);
    background-color: var(--global-color-gray-3);
  }

  .select-all:not(:first-child) {
    margin-left: var(--global-space-single);
  }
}

#backoffice-ods-menu {
  @include flex-horizontal;

  #backoffice-ods-options {
    display: grid;
    grid-template-columns: repeat(6, 150px);

    & > * {
      padding: var(--global-space-single);
      border-bottom: 1px solid var(--global-color-gray-4);
    }

    .header {
      grid-row: 1 / 2;
      font-size: 12px;
      background-color: var(--global-color-lighter-gray);

      &:nth-of-type(2) {
        grid-column: 2 / 7;
      }
    }

    .app-label {
      position: relative;
      grid-column: 1 / 2;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--global-color-blue);
        opacity: 0.07;
      }
    }

    .global-scan-row {
      grid-column: 1 / 6;
    }
  }

  .right-section {
    margin-left: var(--global-space-triple);

    #run-task {
      @include rounded;
      padding: var(--global-space-single);
    }

    .response-message {
      margin-top: var(--global-space-half);
      font-size: 12px;

      &.success {
        color: green;
      }
      &.failure {
        color: red;
      }
    }
  }
}
