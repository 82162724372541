@import 'src/global/mixins.scss';

.tableWrapper {
  width: 90%;
}

.configValueWrapper {
  @include flex-horizontal;
  flex-direction: row;
  overflow-wrap: break-word;
  justify-content: space-between;
  width: 100%;

  .configValueAction {
    margin-left: var(--global-space-double);
    flex-shrink: 0;

    button {
      margin-left: var(--global-space-single);
      width: 80px;

      &:first-of-type {
        width: 60px;
      }
    }
  }

  .configValue {
    @include flex-horizontal;
    flex-grow: 1;
    width: 0;
    min-height: calc(36px + 2 * var(--global-space-single));

    .currentValue {
      overflow: auto;
    }

    .valueEdit {
      @include flex-horizontal;
      width: 100%;
      gap: var(--global-space-single);

      textarea {
        flex-grow: 1;
        padding: var(--global-space-single);
        outline: none;

        &.list {
          height: 100px;
        }
      }
    }
  }
}

.bottomMessage {
  font-size: 12px;
  margin-top: var(--global-space-single);

  &.errorMessage {
    color: red;
  }
}

.ellipsis {
  @include ellipsis;
}
