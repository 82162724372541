@import 'src/global/mixins.scss';

.featureHeader {
  @include flex-horizontal;
  padding: var(--global-space-single) 0;
}

.error {
  color: red;
  font-size: 14px;
}

.actionBtn {
  @include rounded;
  @include flex-center;
  height: 32px;
  min-width: 100px;
  padding: var(--global-space-single);
}

.tablesContainer {
  display: flex;
}

.tableContainer {
  width: 400px;
  margin-right: calc(2 * var(--global-space-triple));

  .table {
    margin-bottom: var(--global-space-double);
  }

  .tableHeaderContainer {
    @include flex-horizontal;
    justify-content: space-between;
    margin-bottom: var(--global-space-double);

    .saveWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .error {
        text-align: end;
      }
    }
  }
}
