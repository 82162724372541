@import 'src/global/mixins.scss';

.tableWrapper {
  width: 1000px;
  max-width: 90%;

  .departments {
    white-space: normal;
  }

  .departmentEdit {
    font-size: 11px;
    color: var(--global-color-blue);
    text-decoration: underline;
    @include transition(opacity);

    &:hover {
      opacity: 0.8;
    }
  }
}
