@import 'src/global/mixins.scss';

$textarea-width: 400px;

.nudgesForm {
  @include flex-start;

  .textAreaContainer {
    @include flex-start;
    flex-direction: column;

    textarea {
      min-width: $textarea-width;
      min-height: 200px;
      margin: 0;
      margin-bottom: var(--global-space-double);
      padding: var(--global-space-single);
    }
  }

  .personsTableContainer {
    width: 360px;
    margin-left: var(--global-space-double);
    padding-bottom: var(--global-space-double);

    .personsTable {
      margin-top: var(--global-space-double);
    }
  }

  .error {
    color: red;
    font-size: 14px;
    margin-top: var(--global-space-single);
  }

  .success {
    color: green;
    font-size: 14px;
    margin-top: var(--global-space-single);
  }
}

.slackMarkdownInstructions {
  width: $textarea-width;
  line-height: 1.5;

  h2 {
    text-decoration: underline;
    margin: var(--global-space-double) 0;
    font-size: 18px;
  }

  section {
    margin: var(--global-space-double) 0;
  }

  code {
    font-family: Courier, serif;
    background-color: var(--global-color-gray-4);
    font-size: 14px;
    margin: 0 2px;
  }

  a {
    text-decoration: underline;
    color: var(--global-color-blue);
  }
}
