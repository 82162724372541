@import 'src/global/mixins.scss';

.inputWrapper {
  @include flex-horizontal;
  margin-bottom: var(--global-space-single);
}

.warning {
  color: orange;
  font-size: 14px;
}
