@import 'src/global/mixins.scss';

.addTenantLogo {
  @include container;
  gap: var(--global-space-single);
  margin: var(--global-space-double) 0;

  .titleWithLogo {
    @include flex-start;
    gap: var(--global-space-double);

    .title {
      align-self: center;
    }

    .img {
      max-height: 50px;
    }
  }

  .inputWrapper {
    @include flex-horizontal;
    margin-bottom: var(--global-space-single);
  }
}
