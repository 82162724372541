@import 'src/global/mixins.scss';

.backofficeScreen {
  display: flex;
  justify-content: left;
  width: 100%;
  max-width: 100vw;
  height: calc(100vh - var(--global-top-navbar-height));
  box-sizing: border-box;
  position: relative;

  h2 {
    margin-bottom: var(--global-space-triple);
  }

  .headerSection {
    display: inline-flex;
    flex-direction: column;
    gap: var(--global-space-single);
    margin-bottom: var(--global-space-double);

    .addButton {
      @include flex-horizontal;

      svg {
        margin-right: var(--global-space-half);
      }
    }
  }
}

.backofficeModalContent {
  @include flex-horizontal;
  flex-direction: column;
  width: min(80%, 600px);
  height: min(80%, 500px);
  overflow: scroll;
  padding-top: var(--global-space-quadruple);

  span {
    margin: var(--global-space-single);
  }

  .formGrid {
    width: 90%;
    display: inline-grid;
    grid-template-columns: 100px auto;
    grid-row-gap: var(--global-space-single);
    margin: var(--global-space-quadruple) 0;
  }
}

.backofficeContentWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  position: relative;

  .backofficeInternalScreen {
    width: 100%;
    height: 100%;
    padding: var(--global-space-triple);
    padding-top: var(--global-space-double);
    text-align: left;
    box-sizing: border-box;

    .tenantSelectionWrapper {
      @include flex-horizontal;
      margin-bottom: var(--global-space-double);

      label {
        margin-right: var(--global-space-single);
      }
    }
  }
}
