@import 'src/global/mixins.scss';

.removeTag {
  color: var(--global-color-gray-1);
  font-size: 18px;
  line-height: 9px;
  padding: var(--global-space-single);
  margin-left: calc(-1 * var(--global-space-single));
  @include transition(color);
  border: 1px solid var(--global-color-gray-4);

  &:hover {
    color: red;
  }

  .buttonText {
    font-size: 14px;
    margin-left: var(--global-space-half);
  }
}

.tagWrapper {
  @include ellipsis;
}
