@import 'src/global/mixins.scss';

.card {
  @include flex-start;
  @include rounded-card;
  flex-direction: column;
  gap: var(--global-space-single);
  width: 100%;
  padding: var(--global-space-double);
  box-sizing: border-box;
  font-size: 12px;
  color: var(--global-color-gray-1);

  .title {
    @include flex-start;
    align-items: center;
    gap: var(--global-space-triple);
    color: var(--global-color-dark-gray);
    font-size: 14px;
    width: 100%;

    .type {
      font-weight: 500;
    }
  }

  .sections {
    @include flex-start;
    flex-direction: column;
    gap: var(--global-space-single);
    width: 100%;

    .section {
      width: 100%;
      @include flex-start;
      flex-direction: column;
      gap: var(--global-space-single);

      .title {
        font-weight: 400;
      }

      .content {
        @include rounded-card;
        width: 100%;
        background-color: var(--global-color-gray-5);
        padding: var(--global-space-single);
        box-sizing: border-box;
      }
    }
  }
}
