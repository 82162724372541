@import 'src/global/mixins.scss';

.filters {
  display: flex;
  gap: var(--global-space-double);

  .filterInput {
    width: 200px;
  }
}

.content {
  display: flex;
  align-items: stretch;
  padding-bottom: var(--global-space-triple);

  .leftSection {
    width: 700px;

    input {
      border-bottom: 1px solid var(--global-color-gray);
      background-color: transparent;
    }
  }

  .rightSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 300px;
    height: 100%;
    padding: var(--global-space-single);
    padding-left: var(--global-space-triple);

    button {
      margin-bottom: var(--global-space-single);
    }

    .bordered {
      color: var(--global-color-blue);
      border: 1px solid var(--global-color-blue);
    }

    .comment {
      font-size: 12px;
      margin-left: var(--global-space-single);
      color: var(--global-color-dark-gray);
    }
  }
}
