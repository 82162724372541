@import 'src/global/mixins.scss';

.screen {
  @include flex-start;
  flex-direction: column;
  padding: var(--global-space-triple);
  padding-bottom: 0;
  gap: var(--global-space-double);
  width: 100%;

  h2 {
    margin-bottom: 0;
  }

  .configuration {
    @include flex-start;
    gap: var(--global-space-double);
    flex-direction: column;

    .filters {
      @include flex-start;
      flex-direction: column;
      gap: var(--global-space-single);

      .filter {
        @include flex-horizontal;
        gap: var(--global-space-double);
      }
    }
  }
}
