.backofficeMenu {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: var(--global-space-single);
  min-width: var(--global-color-gray-4);

  h4 {
    align-self: center;
    margin: var(--global-space-half);
  }

  .link {
    color: var(--global-color-dark-gray);
    padding: var(--global-space-single) var(--global-space-double);
    text-decoration: none;

    &.active {
      background-color: var(--global-color-blue);
      color: white;
    }

    &:hover {
      background-color: var(--global-color-dark-gray);
      color: white;
    }
  }
}
