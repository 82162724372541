.tenant-selection-wrapper {
  .search-wrapper input {
    background-color: white;
    color: var(--global-color-dark-blue);
    border-bottom: 1px solid var(--global-color-gray);
    max-width: 200px;

    &:not(:focus)::placeholder {
      color: var(--global-color-dark-blue);
    }
  }

  .search-wrapper input, .dropdown-menu {
    font-size: 14px;
  }
}
