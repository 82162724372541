@import 'src/global/mixins.scss';

.screen {
  padding: var(--global-space-triple);
  color: var(--global-color-dark-blue);
  width: 100%;
  box-sizing: border-box;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: var(--global-space-triple);

    .search {
      @include flex-horizontal;
      gap: var(--global-space-single);

      .input {
        width: 400px;
      }
    }

    .answer {
      display: flex;
      flex-direction: column;
      color: var(--global-color-dark-blue);
      gap: var(--global-space-triple);

      .answerTitle {
        margin-bottom: var(--global-space-half);
        font-size: 12px;
        opacity: 0.5;
      }
    }
  }
}
